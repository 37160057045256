<template>
  <div class="h-100 py-4">
    <div class="wrapper base-list h-100 px-4" ref="team-list">
      <v-row class="h-100 ma-auto">
        <Loader v-if="fetching" />
        <ag-grid-vue
          v-else
          rowHeight="70"
          style="width: 100%; height: 100%"
          class="ag-theme-alpine admin-user-list"
          :columnDefs="columnDefs"
          :rowClassRules="rowClassRules"
          :rowData="sortedItems"
          @grid-ready="onGridReady"
          @model-updated="onGridUpdated"
          @cell-clicked="onCellClicked"
          :overlayLoadingTemplate="overlayLoadingTemplate"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :aria-selected="true"
          :pagination="true"
          :paginationPageSize="20"
        >
        <!-- @filter-modified="onFilterModified" -->
        </ag-grid-vue>
      </v-row>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import _ from 'lodash';
import Loader from '@/components/common/Loader';

export default {
  components: {
    AgGridVue,
    Loader,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    fetching: {
      type: Boolean,
      default: true,
    },
    role: {
      type: String,
      default: '',
    },
    isSetRows: {
      default: '',
    },
    setRowsData: Function,
  },
  computed: {
    sortedItems() {
      return _.sortBy(this.items, ['creativeType']);
    },
  },
  watch: {
    headers(newVal) {
      this.columnDefs = this.columnDefaultDefs(newVal);
    },
    search(newVal) {
      this.gridApi.setQuickFilter(newVal);
    },
  },
  data() {
    return {
      list: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      rowClassRules: null,
      defaultColDef: null,
      overlayLoadingTemplate: null,
      modules: [],
      rowData: null,
    };
  },
  methods: {
    onCellClicked(params) {
      if (params.colDef.field === 'name' && this.role === 'creative') {
        this.$emit('nameClicked', params.data);
      }
    },
    // onFilterModified(e) {
    //   // console.log('onFilterModified', e);
    //   // console.log('filterInstance.getModel() =>', e.filterInstance.getModel());
    //   this.$emit('cleargetvalue', e.filterInstance.getModelFromUi());
    //   this.getclear = e.filterInstance.getModelFromUi();
    //   this.$emit('clearvalue', this.getclear);
    //   console.log(this.getclear);
    // },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      if (this.fetching) {
        this.gridApi.showLoadingOverlay();
      }
      this.gridApi.sizeColumnsToFit();
      window.addEventListener('resize', this.resizeListener);
    },
    onGridUpdated(params) {
      this.gridApi = params.api;
      if (this.fetching) {
        this.gridApi.showLoadingOverlay();
      }
      this.gridApi.sizeColumnsToFit();
      if (this.gridApi.getDisplayedRowCount()) {
        this.gridApi.hideOverlay();
      } else if (!this.fetching) {
        this.gridApi.showNoRowsOverlay();
      }
      if (this.isSetRows) {
        // eslint-disable-next-line
        let allRows = [];
        this.gridApi.forEachNodeAfterFilter((rowNode) => allRows.push(rowNode));
        this.$emit('setRowsData', allRows);
      }
    },
    columnDefaultDefs(headers) {
      return _.map(headers, (item) => {
        if (_.isObject(item)) {
          return item;
        }
        return {
          headerName: item,
          field: item,
          checkboxSelection: false,
          filter: true,
          autoHeight: true,
        };
      });
    },
    resizeListener() {
      this.gridApi.sizeColumnsToFit();
    },

  },
  async beforeMount() {
    this.gridOptions = { enableCellTextSelection: true };
    this.columnDefs = this.columnDefaultDefs(this.headers);

    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while we load the data...</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">Sorry, no results found! Please check the filters applied, spelling or try searching for something else</span>';
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeListener);
  },
};
</script>

<style lang="scss">
.base-list {
  background: #ffffff;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  padding-top: 1px !important;
  .delete-row {
    background-color: #e8e8e8 !important;
  }
  ::v-deep .ag-center-cols-container {
    width: 100% !important;
  }

  .ag-theme-alpine {
    font-family: $fontFamily1;
  }
  .admin-user-list {
    .newTagColumn.ag-cell {
      padding-right: 0px !important;
      padding-left: 0px !important;
      left: 12px !important;
      display: flex;
      align-items: center;
      .mdi-new-box {
        cursor: auto !important;
      }
    }
  }
}
</style>
