<template>
  <div class="h-100">
    <base-list
      :headers="headers"
      :items="items"
      :fetching="fetching"
      @onEditClick="$emit('onEditClick')"
      @onDeleteClick="$emit('onDeleteClick')"
      :search="search"
    >
    </base-list>
  </div>

</template>

<script>
import moment from 'moment-shortformat';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { truncateText } from '@/helpers';
import BtnCellRenderer from '@/components/projects/ProjectTeam/BtnCellRenderer';
import BaseList from './common/BaseList';

export default {
  components: {
    BaseList,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    headers() {
      const $this = this;
      const items = [
        $this.nameColumn(),
        $this.producersColumn(), $this.creativesColumn(), $this.customerColumn(),
        $this.dateColumn(),
        $this.statusColumn(),
      ];
      if (this.$can('edit', 'project management')) {
        items.push($this.editColumn());
        if (this.role === 'admin') {
          items.push($this.deleteColumn());
        }
      } else {
        items.push($this.viewColumn());
      }
      return items;
    },
    items() {
      return _.map(this.projects, (project) => ({
        id: project.id,
        name: `${project.name}`,
        creativesCount: project.creatives.length,
        creativeNames: _.map(project.creatives, 'firstName').join(', '),
        producerCount: project.producers.length,
        producerNames: _.map(project.producers, 'firstName').join(', '),
        'create date': project.date ? moment(project.date).format('M/D/YY') : null,
        status: this.getStatus(project.status),
        customer: truncateText(_.get(project.business, 'name'), 20),
      }));
    },
  },

  methods: {
    skillsetNames(skillsets) {
      return skillsets.map((x) => (x.name === 'Other' ? x.skillSetName : x.name)).join(', ');
    },
    getStatus(status) {
      switch (status) {
        case '0':
          return 'Inactive';
        case '2':
          return 'Archived';
        default:
          return 'Active';
      }
    },
    editClick(val) {
      this.$emit('onEditClick', val);
    },
    deleteClick(val) {
      this.$emit('onDeleteClick', val);
    },
    nameColumn() {
      return {
        field: 'name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate',
      };
    },
    statusColumn() {
      return {
        headerName: 'status',
        field: 'status',
        width: 95,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: this.statusFilters(),
          suppressAndOrCondition: true,
        },
      };
    },
    dateColumn() {
      return {
        field: 'create date',
        sortable: true,
        filter: true,
        width: 160,
        comparator: (date1, date2) => moment(date2) - moment(date1),
      };
    },
    creativesColumn() {
      return {
        headerName: 'Creatives',
        cellStyle: { textAlign: 'center' },
        headerClass: 'center-align',
        field: 'creativesCount',
        filter: true,
        tooltipField: 'creativeNames',
        cellClass: 'notranslate',
      };
    },
    customerColumn() {
      return {
        headerName: 'customer',
        field: 'customer',
        filter: true,
        tooltipField: 'customerNames',
        cellClass: 'notranslate',
        cellStyle: { textAlign: 'left' },
      };
    },
    producersColumn() {
      return {
        headerName: 'Producers',
        field: 'producerNames',
        cellStyle: { textAlign: 'left' },
        filter: true,
        width: 125,
        tooltipField: 'producerNames',
        cellClass: 'notranslate',
      };
    },
    editColumn() {
      const $this = this;
      return {
        headerName: 'Edit',
        field: 'id',
        headerClass: 'center-align',
        cellClass: 'action pa-2 d-flex justify-center',
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: {
          image: 'edit',
          clicked(params) {
            $this.editClick(params.value);
            return false;
          },
        },
        width: 80,
      };
    },
    viewColumn() {
      const $this = this;
      return {
        headerName: 'View',
        field: 'id',
        cellClass: 'action pa-2 d-flex justify-center cursor-pointer',
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: {
          icon: 'mdi-eye',
          color: '#8066FF',
          clicked(params) {
            $this.editClick(params.value);
            return false;
          },
        },
        width: 80,
      };
    },
    deleteColumn() {
      const $this = this;
      return {
        headerName: '',
        field: 'id',
        headerClass: 'center-align',
        cellClass: 'action pa-2 d-flex justify-center cursor-pointer',
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: {
          image: 'trash',
          clicked(params) {
            $this.deleteClick(params.value);
            return false;
          },
        },
        width: 80,
      };
    },
    statusFilters() {
      const statuses = ['Active', 'Archived'];
      const filters = _.map(statuses, (filter) => ({
        displayKey: filter,
        displayName: filter,
        test(filterValue, cellValue) {
          return cellValue === filter;
        },
        hideFilterInput: true,
      }));
      filters.unshift('empty');
      return filters;
    },
  },
};
</script>

<style scoped>

</style>
