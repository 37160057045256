<template>
  <span class="d-flex align-center cursor-pointer" v-if="params.icon">
    <v-icon :color="params.color" @click="btnClickedHandler()">
      {{params.icon}}
    </v-icon>
  </span>
  <span class="d-flex align-center cursor-pointer" v-else-if="params.image">
     <img :src="require(`@/assets/svg/theme/${params.image}.svg`)" @click="btnClickedHandler()" />
  </span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  methods: {
    btnClickedHandler() {
      this.params.clicked(this.params);
    },
  },
});
</script>
