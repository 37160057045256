<template>
  <div>
    <div class="content-wrapper" h-100>
      <v-overlay :value="showLoader" z-index=8>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-row class="ma-0">
        <v-col class="pa-0 d-flex align-center" lg=5>
          <!-- <div class="d-flex pt-0 mb-0 pb-0 export-button align-center">
            <v-btn text class="text--primary">
              Export All Projects
            </v-btn>
          </div> -->
        </v-col>
        <!-- <v-col cols="3" class="pa-0 d-flex align-center justify-end">
        </v-col> -->
        <v-col class="d-flex align-center justify-end pa-0">
          <BaseInput
            solo
            hide-details
            v-model="search"
            placeholder="Search ..."
            prepend-inner-icon="search"
            class="mt-1 search-box"
            clearable
            @click:clear="search === ''"
          />
          <v-btn
            class="add-button btn-purple"
            @click="openForm"
            v-if="$can('create', 'project management')"
          ><v-icon class="mr-2">mdi-plus</v-icon> Add Project</v-btn>
        </v-col>
      </v-row>
      <div class="listing-block">
        <ProjectList
          :projects="projects"
          :fetching="loading"
          @onEditClick="openEditModal"
          @onDeleteClick="openDeleteModal"
          :search="search"
        />
      </div>
    </div>
    <v-container
      fluid
      v-if="showProjectForm"
    >
      <Modal
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="new-user-form"
        :modal="showProjectForm"
        scrollable
      >
        <AddProject
          @close-modal="closeForm"
          :isAdmin="true"
          :projectObj="selectedProject"
          :canArchive="$can('delete', 'project management')"
        />
      </Modal>
    </v-container>
     <template v-if="showConfirm">
      <v-dialog v-model="showConfirm" max-width="700">
        <v-card class="pa-3" flat>
          <div class="d-flex justify-end">
            <v-icon class="pa-2 pb-1 icon-height" @click="showConfirm = false">
              mdi-close
            </v-icon>
            </div>
            <v-card-text class="modal-heading pl-2 pb-1">
              Are you sure you want to permanently delete all Content Kits and project
              information relevant to
              <span class="project-name">{{ projectName }}</span>?
              This can not be undone.
            </v-card-text>
            <v-row class="ma-0 pa-4 py-3">
              <v-col cols="12" class="d-flex justify-end pa-0">
                  <v-btn text
                    @click="showConfirm = false"
                    class="pa-0 cancel-btn"
                    color="primaryGray1"
                  >
                    Cancel
                  </v-btn>
                  <v-btn text
                    type="submit"
                    class="ml-4 pa-0"
                    color="info"
                    @click="removeProject(projectId)"
                  >
                    Delete
                  </v-btn>
              </v-col>
            </v-row>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
// import EventBus from '@/helpers/event-bus';
import BaseInput from '@/components/common/BaseInput';
import Modal from '@/components/common/Modal';
import AddProject from '@/components/projects/AddProject';
import ProjectList from '@/components/admin/ProjectList';

// import roleBasedDataTable from './roleBasedDataTable';

export default {
  components: {
    AddProject,
    BaseInput,
    Modal,
    ProjectList,
  },

  computed: {
    ...mapGetters('project', ['projects']),
  },

  methods: {
    ...mapActions('project', ['getProjects', 'deleteProject']),
    ...mapActions('profile', ['getClientOptions']),
    async downloadCSV() {
      this.showLoader = true;
      const payload = {
        role: this.selectedUserRole,
        skillIds: this.selectedSkillsets,
        status: this.getSelectedStatus(),
        segmentIds: this.segmentIds,
        searchBy: this.search,
        sortBy: this.sortBy || 'firstName',
        orderBy: this.orderBy,
        perPage: 'ALL',
      };
      const result = await this.getExportUsers(payload);
      if (result.success) {
        const arrData = this.csvData(result.users);
        const csvContent = [
          _.map(Object.keys(arrData[0]), (key) => key.toUpperCase()).join(';'),
          ...arrData.map((item) => Object.values(item).join(';')),
        ]
          .join('\n')
          .replace(/(^\[)|(\]$)/gm, '');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'projects.csv');
        link.click();
        this.showLoader = false;
      }
    },
    async removeProject(id) {
      const result = await this.deleteProject(id);
      if (result.success) {
        this.showConfirm = false;
      }
    },
    async closeForm() {
      this.showProjectForm = false;
      this.selectedProject = {};
    },
    openForm() {
      this.showProjectForm = true;
    },
    openEditModal(id) {
      this.showLoader = true;
      this.selectedProject = _.cloneDeep(_.find(this.projects, ['id', id]));
      this.selectedProject.subscriptionBlendIds = _.get(this.selectedProject, 'subscriptionBlends[0]', {}).id;
      this.selectedProject.producerIds = _.map(this.selectedProject.producers, 'id');
      this.selectedProject.executiveIds = _.map(this.selectedProject.executives, 'id');
      this.selectedProject.editorIds = _.map(this.selectedProject.editors, 'id');
      this.selectedProject.clientProfileId = _.get(this.selectedProject.business, 'id');
      this.selectedProject.verticalId = _.get(this.selectedProject.business, 'businessTypeId', null);
      this.selectedProject.pointOfContactId = _.get(this.selectedProject, 'pointOfContactId', null);
      this.showProjectForm = true;
      this.showLoader = false;
    },
    openDeleteModal(id) {
      this.showConfirm = true;
      this.selectedProject = _.cloneDeep(_.find(this.projects, ['id', id]));
      this.projectName = this.selectedProject.name;
      this.projectId = this.selectedProject.id;
    },
  },
  async mounted() {
    this.loading = true;
    await this.getProjects();
    await this.getClientOptions();
    this.loading = false;
  },
  data() {
    return {
      showProjectForm: false,
      search: '',
      status: '',
      loading: false,
      showLoader: false,
      selectedProject: {},
      showConfirm: false,
      projectName: '',
      projectId: '',
    };
  },
};
</script>

<style scoped lang="scss">
.padding-right{
  padding-right:60px !important;
}
.heading-text {
  font-family: $fontFamily1;
  font-size: 24px;
  font-weight: normal !important;
}
.sort-dropdown {
  max-width: 30%;
}
.roles-dropdown {
  max-width: 150px;
  ::v-deep &.v-text-field {
    font-size: 16px;
    input {
      visibility: hidden
    }
  }
}
.roles-dropdown, .sort-dropdown {
  ::v-deep .v-input__slot {
    background: transparent !important;
  }
}
::v-deep .v-label {
  font-family: $fontFamily1;
  font-size: 14px;
  color: $neutral1;
}
.sort-text {
  font-family: $fontFamily1;
  font-size: 14px;
}
::v-deep .v-text-field {
  font-family: $fontFamily1;
  font-size: 14px;
}
.search-box ::v-deep {
  .v-input__slot {
    border: 1px solid  #b0b0b0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .v-input__control {
    input {
      max-height: 34px !important;
    };
  }
}
.heading {
  .text {
    font-size: 24px;
    color: $neutral1;
    text-transform: capitalize;
  }
}
.add-button, .export-button {
  ::v-deep .v-btn__content {
    font-family: $fontFamily1;
    font-size: 16px;
    letter-spacing: -0.32px;
  }
}
.search-box {
  ::v-deep .v-text-field {
    height: 40px;
    font-size: 16px;
  }
}
.modal-heading {
  font-family: $fontFamily1;
  font-size: 18px;
  font-weight: normal;
  text-align: left;
  color: #1b1c1e !important;
}
.cancel-btn {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
.project-name {
  font-weight: bold;
  text-transform: uppercase
}

.filter-box {
  width: 90%;
  border:1px solid  #b0b0b0 !important;
  border-radius: 0 !important;
  color: black;
  cursor: pointer;
  max-height: 36px;
  font-family: $fontFamily1;
  font-size: 16px;
}
.v-data-table {
  ::v-deep th {
    font-weight: bold;
    font-size: 16px;
    color: $neutral1 !important;
  }
  ::v-deep td {
    font-size: 14px;
    color: $neutral1;
    font-family: $fontFamily1;
  }
  ::v-deep td:nth-child(1) {
    color: #0373d1;
  }
  ::v-deep tr:nth-child(even) {
    background-color: #f8f8f8;
  }
}
.heading {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: bold;
  color: $neutral1;
}
.filter-wrapper {
  background-color: #f8f8f8;
  border: 1px solid #dddddd;
}
.filter-item {
  padding: 12px 20px 0;
  // flex-basis: 25%;
}
.filter-checkbox {
  ::v-deep {
    .v-icon {
      background-color: white !important;
      border-radius: 6px;
      border: 2px solid #cfd5dd !important;
      &.mdi-check {
        border: 2px solid $primary1 !important;
        font-size: 16px;
      }
    }
  }
}
.modal-card {
  position: absolute;
  right: 0px;
  width:500px;
  font-family: $fontFamily1;
  font-size: 14px;
  top: 50px;
  z-index: 10;
  .card-list {
    background-color: #f8f8f8;
  }
}

.listing-block {
  height: calc(100vh - 150px);
  overflow: auto;
}
@media (min-width: 600px) {
  .border-right {
    border-right: 1px solid #dddddd;
  }
}
@media (max-width: 600px) {
  .listing-block {
    height: calc(100vh - 165px);
  }
}
</style>
