<template>
  <v-form class="add-project-form" @submit="submitForm">
    <v-container class="pa-5">
      <v-row>
        <v-col class="d-flex align-center pb-1" cols=10>
          <span class="text modal-header-title"> {{title}} </span>
        </v-col>
        <v-col class="d-flex justify-end pb-1">
          <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <BaseInput
            label="Project Name *"
            name="projectName"
            v-model.trim="project.name"
            :status="errors('name').length ? 'error': 'normal'"
            :error-messages="errors('name')"
            @input="$v.project.name.$touch()"
            @blur="$v.project.name.$touch()"
          />
        </v-col>
        <v-col cols="6" class="pb-0">
          <DatePicker
            label="Date *"
            name="date"
            v-model="project.date"
            :initialValue="project.date"
            :status="errors('date').length ? 'error': 'normal'"
            :error-messages="errors('date')"
            @input="$v.project.date.$touch()"
            @blur="$v.project.date.$touch()"
          />
        </v-col>
        <v-col cols="6" class="pb-0 pt-4">
          <AutoCompleteField
            :items="projectDependencies.businesses"
            label="Customer Account *"
            name="businessId"
            v-model="project.businessId"
            item-text="name"
            item-value="id"
            notClearable
            class="notranslate"
            :status="errors('businessId').length ? 'error': 'normal'"
            :error-messages="errors('businessId')"
            @input="$v.project.businessId.$touch()"
            @blur="$v.project.businessId.$touch()"
          />
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-row class="ma-0">
            <v-col cols="6" class="pa-0 pr-3">
              <v-select
                label="Select your business vertical"
                class="type-selection mb-n3 notranslate"
                :items="businessTypes"
                item-text="name"
                item-value="id"
                v-model="project.verticalId"
                @change="verticalUpdated = true"
                :menu-props="{'content-class' : 'notranslate'}"
              ></v-select>
            </v-col>
            <!-- <v-col cols="6" class="pa-0 pl-3">
              <AutoCompleteField
                :items="filteredSubscriptionBlends"
                label="Subscription Blend *"
                name="subscriptionBlendIds"
                v-model="project.subscriptionBlendIds"
                item-text="name"
                item-value="id"
                notClearable
                class="notranslate"
                :status="errors('subscriptionBlendIds').length ? 'error': 'normal'"
                :error-messages="errors('subscriptionBlendIds')"
                @input="$v.project.subscriptionBlendIds.$touch()"
                @blur="$v.project.subscriptionBlendIds.$touch()"
              />
            </v-col> -->
          </v-row>
        </v-col>
        <v-col cols="12" class="pb-0">
          <AutoCompleteField
            :items="projectDependencies.producersList"
            multiple
            label="Content Producer"
            name="producerIds"
            :value="project.producerIds"
            @change="setProducers"
            class="notranslate"
            item-text="label"
            item-value="value"
          />
        </v-col>
        <v-col cols="12" class="pb-0">
          <AutoCompleteField
            :items="projectDependencies.executivesList"
            multiple
            label="Production Manager"
            name="executiveIds"
            :value="project.executiveIds"
            @change="setExecutives"
            class="notranslate"
            item-text="label"
            item-value="value"
          />
        </v-col>
         <v-col cols="12" class="pb-0">
          <AutoCompleteField
            :items="projectDependencies.editorsList"
            multiple
            label="Editor"
            name="editorIds"
            v-model="project.editorIds"
            class="notranslate"
            item-text="label"
            item-value="value"
          />
        </v-col>
        <v-col cols="12" class="pb-0">
          <AutoCompleteField
            :items="contactsList"
            label="Point of Contact"
            name="pointOfContactId"
            :value="JSON.parse(project.pointOfContactId)"
            @change="setContact"
            class="notranslate"
            item-text="label"
            item-value="value"
            notClearable
          />
        </v-col>
        <v-col cols="12" class="pb-0">
          <label class="text-left font-label"> Banner Image </label>
          <Upload
            id="upload-documents"
            class="upload-button"
            mode="justImage"
            v-model="project.bannerImage"
            :acceptedFiles="'.jpg,.jpeg,.png,.svg,.gif'"
          >
            <template v-if="!project.bannerImage">
              <span class="add-text h-100">Upload</span>
            </template>
          </Upload>
          <v-chip
            v-if="project.bannerImage"
            close
            @click:close="deleteBannerImage()"
            color="#e5e7fa"
            class="agreement-file mt-1 notranslate"
            :close-icon="'mdi-close'"
          >
            {{ bannerImage }}
          </v-chip>
        </v-col>
        <v-col cols="12" class="pb-0">
          <label class="text-left font-label"> Sweep Key Info </label>
          <tiptap-vuetify
            v-model="project.brandProfile"
            :extensions="extensions"
            :autoFocus="true"
            class="my-1 notranslate"
          />
        </v-col>
        <v-col cols="12" class="pb-0 mb-1">
          <label class="text-left font-label"> Deliverables </label>
          <tiptap-vuetify
            v-model="project.deliverables"
            :extensions="deliverablesExtensions"
            :autoFocus="true"
            class="my-1 notranslate"
          />
        </v-col>
        <v-col cols="12" class="pb-0 mb-4">
          <label class="text-left mb-1 font-label"> Creative Direction </label>
          <tiptap-vuetify
            v-model="project.valueProps"
            :extensions="extensions"
            :autoFocus="true"
            class="my-1 notranslate"
          />
        </v-col>
        <v-col cols="12" class="pb-0 mb-4">
          <label class="text-left mb-1 font-label"> Status </label>
          <v-select
            class="type-selection notranslate"
            solo
            :items="statusItems"
            v-model="project.status"
            hide-details
            :menu-props="{'content-class' : 'notranslate'}"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-end py-2">
          <v-btn text color="primaryGray1"
            @click="$emit('close-modal')"
            class="py-0"
          >
            Close
          </v-btn>
          <v-btn text
            type="submit"
            class="pa-0 ml-2 btn-purple"
            :loading="loading"
          >
            {{ action }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import {
  TiptapVuetify,
  Bold,
  BulletList,
  ListItem,
  Italic,
  Underline,
  Paragraph,
  Link,
  Strike,
  OrderedList,
} from 'tiptap-vuetify';
import { url } from '@/helpers';
import BaseInput from '@/components/common/BaseInput';
import AutoCompleteField from '@/components/common/AutoCompleteField';
import DatePicker from '@/components/common/DatePicker';
import Upload from '@/components/common/Upload';

export default {
  components: {
    BaseInput,
    AutoCompleteField,
    DatePicker,
    Upload,
    TiptapVuetify,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default() {
        return false;
      },
    },
    projectObj: {
      type: Object,
      default: () => {},
    },
    canArchive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('profile', ['businessTypes']),
    contactsList() {
      const { producersList, executivesList } = this.projectDependencies;
      return _.concat(producersList, executivesList);
    },
    // filteredSubscriptionBlends() {
    //   if (this.project.verticalId) {
    //     const filtered = _.filter(this.projectDependencies.subscriptionBlends,
    //       { verticalId: this.project.verticalId });
    //     if (this.verticalUpdated) return filtered;
    //     const legacy = !_.map(filtered, 'id').includes(this.project.subscriptionBlendIds);
    //     if (this.project.id && legacy) {
    //       return this.projectDependencies.subscriptionBlends;
    //     }
    //     return filtered;
    //   }
    //   return this.projectDependencies.subscriptionBlends;
    // },
    title() {
      if (this.projectObj.id) {
        return 'Edit Project';
      }
      return 'Add New Project';
    },
    action() {
      if (this.projectObj.id) {
        return 'Update Project';
      }
      return 'Add Project';
    },
    bannerImage() {
      if (_.isObject(this.project.bannerImage)) {
        return this.project.bannerImage.name;
      }
      return 'Banner Image';
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.project[field].$dirty) return errors;
        switch (field) {
          case 'name':
            if (!this.$v.project.name.required) { errors.push('Please provide valid name'); }
            break;
          case 'date':
            if (!this.$v.project.date.required) { errors.push('Please provide valid date'); }
            break;
          case 'businessId':
            if (!this.$v.project.businessId.required) { errors.push('Please select a customer account'); }
            break;
          // case 'subscriptionBlendIds':
          //   if (!this.$v.project.subscriptionBlendIds.required)
          //  { errors.push('Please select a subscription blend'); }
          //   break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  data() {
    return ({
      pointOfContactRole: '',
      projectDependencies: {
        clientsList: [],
        producersList: [],
        subscriptionBlends: [],
      },
      project: {
        name: '',
        date: new Date().toISOString().substr(0, 10),
        businessId: '',
        // subscriptionBlendIds: '',
        producerIds: '',
        executiveIds: [],
        editorIds: [],
        bannerImage: '',
        status: '1',
        pointOfContactId: null,
      },
      statusItems: [
        { text: 'Active', value: '1' },
        { text: 'Archived', value: '2', disabled: !this.canArchive },
      ],
      loading: false,
      extensions: [
        Bold,
        BulletList,
        ListItem,
        Italic,
        Underline,
        Paragraph,
        Link,
        Strike,
        OrderedList,
      ],
      deliverablesExtensions: [
        Bold,
        BulletList,
        ListItem,
        Italic,
      ],
      verticalUpdated: false,
    });
  },
  methods: {
    ...mapActions('project', ['addProject', 'updateProject']),
    ...mapActions('user', ['fetchUsers', 'fetchBusinesses']),
    ...mapActions('project', ['getSubscriptionBlends']),
    ...mapActions(['setNotification']),
    async setProducers(newval) {
      const contactId = _.get(this.project, 'pointOfContactId', null);
      const pointOfContactId = contactId ? JSON.parse(contactId) : null;
      this.project.producerIds = newval;
      if (this.pointOfContactRole === 'producer' && pointOfContactId
          && ((newval.length === 0) || (newval.length > 0
          && !newval.includes(pointOfContactId)))) {
        this.project.producerIds.push(pointOfContactId);
        this.setNotification({
          message: 'Cannot update point of contact!',
          type: 'error',
        }, { root: true });
      }
    },
    async setExecutives(newval) {
      const contactId = _.get(this.project, 'pointOfContactId', null);
      const pointOfContactId = contactId ? JSON.parse(contactId) : null;
      this.project.executiveIds = newval;
      this.selectedExectiveIds = await _.filter(newval,
        (val) => val !== pointOfContactId);
      if (this.pointOfContactRole === 'executive' && pointOfContactId
          && ((newval.length === 0) || (newval.length > 0
          && !newval.includes(pointOfContactId)))) {
        this.project.executiveIds.push(pointOfContactId);
        this.setNotification({
          message: 'Cannot update point of contact!',
          type: 'error',
        }, { root: true });
      }
    },
    async setContact(data) {
      if (data) {
        this.project.pointOfContactId = data;
        const producer = _.filter(this.project.producerIds, (val) => val === data);
        const executive = _.filter(this.project.executiveIds, (val) => val === data);
        if (producer.length === 0) {
          this.project.producerIds.push(data);
        }
        if (executive.length === 0) {
          this.project.executiveIds.push(data);
        }
      } else {
        this.project.pointOfContactId = null;
      }
      const { producersList, executivesList } = this.projectDependencies;
      const isExective = _.includes(_.map(executivesList, (item) => item.value),
        data)
        ? 'executive' : '';
      const isProducer = _.includes(_.map(producersList, (item) => item.value),
        data)
        ? 'producer' : '';
      this.pointOfContactRole = isProducer || isExective;
    },
    async submitForm(e) {
      this.loading = true;
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const formData = new FormData();
        const project = {
          ...this.project,
          // subscriptionBlendIds: [this.project.subscriptionBlendIds],
        };
        _.map(project, (val, key) => {
          if (key === 'bannerImage' && !url(val)) {
            formData.append('bannerImage', val);
          } else if (val !== null) {
            formData.append(key, _.isObject(val) ? JSON.stringify(val) : val);
          }
        });
        if (!this.project.pointOfContactId) {
          formData.append('pointOfContactId', null);
        }
        let result;
        if (this.project.id) {
          result = await this.updateProject({
            id: this.project.id,
            project: formData,
            isAdmin: this.isAdmin,
          });
        } else {
          result = await this.addProject({ project: formData, isAdmin: this.isAdmin });
        }
        if (result.success) {
          setTimeout(() => {
            this.$emit('close-modal');
          }, 1000);
        }
      }
      this.loading = false;
    },
    async fetchProjectDependencies() {
      const [
        executivesList,
        producersList,
        editorsList,
        subscriptionBlends,
        businesses] = await Promise.all([
        // this.fetchUsers('client'),
        this.fetchUsers('executive'),
        this.fetchUsers('producer'),
        this.fetchUsers('editor'),
        this.getSubscriptionBlends(),
        this.fetchBusinesses(),
      ]);
      // this.projectDependencies.clientsList = _.map(
      //   _.filter(clientsList.users, (client) => client.clientProfile),
      //   (client) => ({
      //     label: `${client.firstName} ${client.lastName}`,
      //     value: client.clientProfile.id,
      //   }),
      // );
      const nullNames = _.filter(businesses.business, 'name', '');
      const sortedBusiness = _.sortBy(nullNames, [(item) => item.name.toLowerCase()], ['asc']);
      this.projectDependencies.businesses = sortedBusiness;
      const executives = _.map(executivesList.users, (executive) => ({
        label: `${executive.firstName} ${executive.lastName ? executive.lastName : ''}`,
        value: executive.id,
      }));
      this.projectDependencies.executivesList = _.sortBy(executives, [(item) => item.label.toLowerCase()], ['asc']);
      const allProducers = _.filter(producersList.users, (user) => (!user.isTrainee
        || (this.project.producerIds && this.project.producerIds.includes(user.id))));
      const producers = _.map(allProducers, (producer) => ({
        label: `${producer.firstName} ${producer.lastName ? producer.lastName : ''}`,
        value: producer.id,
      }));
      this.projectDependencies.producersList = _.sortBy(producers, [(item) => item.label.toLowerCase()], ['asc']);

      const editors = _.map(editorsList.users, (editor) => ({
        label: `${editor.firstName} ${editor.lastName ? editor.lastName : ''}`,
        value: editor.id,
      }));
      this.projectDependencies.editorsList = _.sortBy(editors, [(item) => item.label.toLowerCase()], ['asc']);
      this.projectDependencies.subscriptionBlends = subscriptionBlends.subscriptionBlends;
      const isExective = _.includes(_.map(this.projectDependencies.executivesList,
        (item) => item.value), JSON.parse(this.projectObj.pointOfContactId))
        ? 'executive' : '';
      const isProducer = _.includes(_.map(this.projectDependencies.producersList,
        (item) => item.value), JSON.parse(this.projectObj.pointOfContactId))
        ? 'producer' : '';
      this.pointOfContactRole = isProducer || isExective;
    },
    deleteBannerImage() {
      this.project.bannerImage = '';
    },
  },
  validations: {
    project: {
      name: { required },
      date: { required },
      businessId: { required },
      // subscriptionBlendIds: { required },
    },
  },
  mounted() {
    this.fetchProjectDependencies();
    if (this.projectObj.id) {
      this.project = { ...this.projectObj };
      this.project.pointOfContactId = this.projectObj.pointOfContactId;
    } else if (!this.isAdmin) {
      if (_.get(this.userDetails, 'role.name', '') === 'executive') {
        this.project.executiveIds = [this.userDetails.id];
      } else if (_.get(this.userDetails, 'role.name', '') === 'producer') {
        this.project.producerIds = [this.userDetails.id];
      }
    }
  },
};
</script>

<style lang="scss" scoped>
  .auto-complete {
    margin-bottom: 0;
  }
  .heading {
      font-size: 24px;
      letter-spacing: -0.48px;
      color: $card-title;
  }
  ::v-deep .v-picker.v-card {
      border-radius: 0 !important;
  }
  ::v-deep .v-btn__content {
      background: transparent;
      color: $secondary1;
      font-family: $fontFamily1;
      font-size: 16px;
      letter-spacing: -0.32px;
      text-transform: uppercase;
  }
  ::v-deep .input-field {
    font-family: $fontFamily1;
    height: inherit;
  }
  ::v-deep .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }
  ::v-deep .v-select__slot {
    font-family: $fontFamily1;
  }
  ::v-deep .v-input {
    input:-webkit-autofill {
      -webkit-box-shadow:0 0 0 50px white inset;
    }
  }
  .upload-button {
    font-family: $fontFamily1;
    font-size: 12px;
    letter-spacing: -0.01px;
    color: $secondary1;
    span {
      cursor: pointer !important;
    }
  }
  ::v-deep .tiptap-vuetify-editor .v-card {
    border-radius: 0 !important;
  }
  ::v-deep .type-selection .v-input__slot {
    box-shadow: none !important;
  }
</style>
